import { tokens } from "@bees-grow-shared/theme";
import { Button as HexaButton } from "@hexa-ui/components";
import styled from "styled-components";

export const Container = styled.div`
  gap: ${tokens.measure.space["spacing-4"]};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const Button = styled(HexaButton)`
  font-family: ${tokens.fonts["font-family"]["Work Sans"]};
  font-weight: ${tokens.fonts["font-weight"].medium};
  padding: ${tokens.measure.space["spacing-2"]};
  padding-right: ${tokens.measure.space["spacing-4"]};

  &:disabled {
    color: ${tokens.color.foundation.txt.disabled};

    div {
      background: ${tokens.color.flavor.rule.bg.button["button-circle"]
        .disabled};
    }
  }
`;

export const CloseIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
`;

export const TextButtonContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${tokens.measure.space["spacing-2"]};
  padding: ${tokens.measure.space["spacing-2"]}
    ${tokens.measure.space["spacing-4"]};

  color: ${tokens.color.flavor.rule.txt["general-actions"].default};
  svg path {
    fill: ${tokens.color.flavor.rule.txt["general-actions"].default};
  }

  &:hover {
    color: ${tokens.color.flavor.rule.txt["general-actions"].hover};
    svg path {
      fill: ${tokens.color.flavor.rule.txt["general-actions"].hover};
    }
  }

  &:active {
    cursor: default;
    color: ${tokens.color.flavor.rule.txt["general-actions"].pressed};
    svg path {
      fill: ${tokens.color.flavor.rule.txt["general-actions"].pressed};
    }
  }
`;
