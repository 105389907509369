import { useIntl } from "react-intl";

import { TooltipGeneric } from "@bees-grow-shared/components";
import { useBlockTooltip } from "@bees-grow-shared/services";

import SponsoredBadge from "@/assets/SponsoredBadge";

import * as Styled from "./CardTitle.styles";

interface TitleProps {
  refContent: React.MutableRefObject<HTMLParagraphElement>;
  title: string;
}

interface CardTitleProps {
  title: string;
  isSponsored: boolean;
}

const TextTitle = ({ refContent, title }: TitleProps) => (
  <Styled.Title
    data-testid={"card-title-text-title"}
    ref={refContent}
    size="small"
    weight="semibold"
  >
    {title}
  </Styled.Title>
);

export default function CardTitle({ title, isSponsored }: CardTitleProps) {
  const { formatMessage } = useIntl();
  const { refContent, blockByWidth } = useBlockTooltip();

  return (
    <Styled.Container>
      <Styled.TitleContainer>
        {blockByWidth ? (
          <TextTitle refContent={refContent} title={title} />
        ) : (
          <TooltipGeneric placement="top" text={title}>
            <TextTitle refContent={refContent} title={title} />
          </TooltipGeneric>
        )}
      </Styled.TitleContainer>
      {isSponsored && (
        <TooltipGeneric
          placement="top"
          text={formatMessage({
            id: "TasksCarousel.TaskCard.TOOLTIP_SPONSORED",
          })}
        >
          <SponsoredBadge />
        </TooltipGeneric>
      )}
    </Styled.Container>
  );
}
