import * as Styled from "./LoadingCard.styles";

export default function LoadingCard() {
  return (
    <Styled.Container>
      <Styled.HeaderSection>
        <Styled.ImageSkeleton />

        <div>
          <Styled.HeaderInfoSection>
            <Styled.TitleSkeleton />
            <Styled.TitleSecondLineSkeleton />

            <Styled.DescriptionSection>
              <Styled.SkuSkeleton />
              <Styled.BulletDivider />

              <Styled.VolumeterSkeleton />
              <Styled.BulletDivider />

              <Styled.PackageQuantitySkeleton />
            </Styled.DescriptionSection>
          </Styled.HeaderInfoSection>

          <Styled.UnitSkeleton />
        </div>
      </Styled.HeaderSection>

      <Styled.BodySection>
        <Styled.PriceSkeleton />
        <Styled.QuantitySection>
          <Styled.QuantityInfo>
            <Styled.QuantifierInput disabled />
          </Styled.QuantityInfo>
          <Styled.EmptyButton />
        </Styled.QuantitySection>
        <Styled.InfoSkeleton />
      </Styled.BodySection>
    </Styled.Container>
  );
}
