import { tokens } from "@bees-grow-shared/theme";
import { IconButton } from "@hexa-ui/components";
import styled from "styled-components";

interface ContainerProps {
  markAsDone: boolean;
}

export const Container = styled.div<ContainerProps>`
  background-color: ${(props) =>
    props.markAsDone
      ? tokens.color.foundation.bg.success.secondary
      : tokens.color.component.bg.card.neutral.weak};
  border-radius: ${tokens.measure["border-radius"]["borderradius-2"]};
  padding: ${tokens.measure.space["spacing-4"]};
  gap: ${tokens.measure.space["spacing-2"]};
  height: 4.75rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Content = styled.div`
  gap: ${tokens.measure.space["spacing-1"]};
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
`;

export const DetailsButton = styled(IconButton)`
  align-self: center;
  width: 2rem;
  height: 2rem;
`;
