import { tokens } from "@bees-grow-shared/theme";
import { Heading, SkeletonLoader } from "@hexa-ui/components";
import styled from "styled-components";

export const Container = styled(Heading)`
  font-family: ${tokens.fonts["font-family"].Barlow};
  font-size: ${tokens.measure["font-size"]["fontsize-6"]};
  font-weight: ${tokens.fonts["font-weight"].semibold};
  line-height: ${tokens.measure["line-height"]["lineheight-7"]};
  text-align: left;
`;

export const ContainerLoading = styled.div`
  padding: 0.25rem 0;
`;

export const LineLoading = styled(SkeletonLoader)`
  border-radius: ${tokens.measure["border-radius"]["borderradius-1"]};
  height: 1.5rem;
  width: 11.625rem;
`;
