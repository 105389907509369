import { tokens } from "@bees-grow-shared/theme";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.measure.space["spacing-4"]};
  padding: ${tokens.measure.space["spacing-6"]} 0;
  margin: 0 auto;
  min-width: 55.5rem;
  max-width: 113rem;
`;
