import { axiosInstance } from "@bees-grow-shared/services";

import { GROW_ORDER_TAKEN_SERVICE_ENDPOINTS } from "../endpoints";
import {
  GetMissionDetailsRequestHeaders,
  GetMissionDetailsRequestQuery,
} from "./model/GetMissionDetailsRequest";
import { GetMissionDetailsResponse } from "./model/GetMissionDetailsResponse";

interface GetMissionDetailsProps {
  query: GetMissionDetailsRequestQuery;
  headers: GetMissionDetailsRequestHeaders;
}

export async function getMissionDetails({
  query,
  headers,
}: GetMissionDetailsProps): Promise<GetMissionDetailsResponse> {
  const { data } = await axiosInstance.get<GetMissionDetailsResponse>(
    GROW_ORDER_TAKEN_SERVICE_ENDPOINTS.GET_MISSION_DETAILS({ ...query }),
    { headers: { ...headers } },
  );

  return data[0];
}
