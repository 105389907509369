import { tokens } from "@bees-grow-shared/theme";
import styled from "styled-components";

interface GridItemProps {
  isLastInRow: boolean;
  isLastInColumn: boolean;
}

export const BORDER_PROPERTIES = `1px solid ${tokens.color.foundation.border.secondary};`;

export const getBorderRightStyle = (isLastInRow: boolean): string =>
  isLastInRow ? "none" : BORDER_PROPERTIES;

export const getBorderBottomStyle = (isLastInColumn: boolean): string =>
  isLastInColumn ? "none" : BORDER_PROPERTIES;

export const ResponsiveCard = styled.div`
  text-align: start;
  height: auto;
  box-sizing: border-box;
`;

export const GridCard = styled(ResponsiveCard)<GridItemProps>`
  border-right: ${({ isLastInRow }) => getBorderRightStyle(isLastInRow)};
  border-bottom: ${({ isLastInColumn }) =>
    getBorderBottomStyle(isLastInColumn)};
`;
