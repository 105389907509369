import { tokens } from "@bees-grow-shared/theme";
import { Heading, SkeletonLoader } from "@hexa-ui/components";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
`;

export const Title = styled(Heading)`
  margin: 0;
  font-family: ${tokens.fonts["font-family"].Barlow};
  font-size: ${tokens.measure["font-size"]["fontsize-6"]};
  font-weight: ${tokens.fonts["font-weight"].semibold};
  line-height: ${tokens.measure["line-height"]["lineheight-7"]};
  text-align: left;
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${tokens.measure.space["spacing-4"]};
`;

export const LoadingContainer = styled.div`
  padding-top: ${tokens.measure.space["spacing-2"]};
`;

export const TitleLoad = styled(SkeletonLoader)`
  height: ${tokens.measure["line-height"]["lineheight-7"]};
  width: 7.5rem;
`;
