export default function SponsoredBadge() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#clip0_9398_5697)">
        <path
          fill="#141414"
          fillRule="evenodd"
          d="M8.042 1.5a4.292 4.292 0 00-2.589 7.715l-.615 4.636-.004.037a.5.5 0 00.757.457l2.45-1.47 2.452 1.47.033.019a.5.5 0 00.72-.513l-.615-4.636A4.292 4.292 0 008.042 1.5zm1.825 7.031a.497.497 0 00-.084.055 3.292 3.292 0 11.084-.054zm-.174 1.223l.425 3.2-1.82-1.091-.036-.02a.5.5 0 00-.478.02l-1.819 1.091.426-3.2c.508.212 1.066.33 1.65.33.586 0 1.144-.118 1.652-.33z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_9398_5697">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}
