import { useCallback, useEffect } from "react";

import {
  accountSharedService,
  cartSharedService,
  lastOrdersSharedService,
  useSharedUnit,
} from "@bees-grow-shared/services";

export function useProductSelectionHeader() {
  const lastOrdersService = lastOrdersSharedService();
  const { done, isLoading: isLastOrdersLoading } =
    useSharedUnit(lastOrdersService);

  const accountService = accountSharedService();
  const { accountId, vendorId } = useSharedUnit(accountService);

  const cartService = cartSharedService();
  const {
    getCartStatus: { isLoading: isCartStatusLoading },
  } = useSharedUnit(cartService);

  const getLastOrders = useCallback(() => {
    lastOrdersService.getLastOrders({
      accountId,
      vendorId,
    });
  }, [accountId, lastOrdersService, vendorId]);

  useEffect(() => {
    if (!done && !isLastOrdersLoading) {
      getLastOrders();
    }
  }, [done, getLastOrders, isLastOrdersLoading]);

  return {
    isLoading: isCartStatusLoading,
  };
}
