import { tokens } from "@bees-grow-shared/theme";
import { Paragraph } from "@hexa-ui/components";
import styled from "styled-components";

export const Container = styled.div`
  gap: ${tokens.measure.space["spacing-1"]};
  display: flex;
  flex-direction: row;
`;

export const TitleContainer = styled.div`
  max-width: 100%;
`;

export const Title = styled(Paragraph)`
  line-height: ${tokens.measure["line-height"]["lineheight-5"]};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
