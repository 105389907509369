import { Truck } from "@hexa-ui/icons";

import * as Styled from "./CheckoutButton.styles";
import { useCheckoutButton } from "./hooks/useCheckoutButton";

export default function CheckoutButton() {
  const { cartQuantity, showCartQuantity, handleGoToCheckout } =
    useCheckoutButton();

  return (
    <Styled.Container data-testid="checkout-button">
      <Styled.IconButton
        icon={Truck}
        variant="tertiary"
        onClick={handleGoToCheckout}
      />
      {showCartQuantity && (
        <Styled.PositionedBadge
          data-testid="checkout-button-cart-badge"
          quantity={cartQuantity}
        />
      )}
    </Styled.Container>
  );
}
