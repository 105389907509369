import { tokens } from "@bees-grow-shared/theme";
import { Paragraph } from "@hexa-ui/components";
import styled from "styled-components";

export const Description = styled(Paragraph)`
  line-height: ${tokens.measure["line-height"]["lineheight-5"]};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const Container = styled.div`
  max-height: 100%;
`;
