import { TooltipGeneric } from "@bees-grow-shared/components";
import { useBlockTooltip } from "@bees-grow-shared/services";

import * as Styled from "./CardDescription.styles";

interface DescriptionProps {
  refContent: React.MutableRefObject<HTMLParagraphElement>;
  description: string;
}

interface CardDescriptionProps {
  description: string;
}

const TextDescription = ({ description, refContent }: DescriptionProps) => (
  <Styled.Description
    data-testid={"card-description-text-description"}
    ref={refContent}
    size="small"
    weight="normal"
  >
    {description}
  </Styled.Description>
);

export default function CardDescription({ description }: CardDescriptionProps) {
  const { refContent, blockByHeight } = useBlockTooltip();

  return (
    <Styled.Container>
      {blockByHeight ? (
        <TextDescription refContent={refContent} description={description} />
      ) : (
        <TooltipGeneric placement="bottom" text={description}>
          <TextDescription refContent={refContent} description={description} />
        </TooltipGeneric>
      )}
    </Styled.Container>
  );
}
