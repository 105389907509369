import { constructEndpoint } from "@bees-grow-shared/services";

import { MICROSERVICES } from "../MicroServices";

enum ENDPOINTS {
  GET_MISSION_DETAILS = "/accounts/{accountId}/missions",
}

export interface GetMissionDetailsParams {
  accountId: string;
  missionKey: string;
}

export const GROW_ORDER_TAKEN_SERVICE_ENDPOINTS = {
  GET_MISSION_DETAILS: ({ accountId, missionKey }: GetMissionDetailsParams) =>
    constructEndpoint({
      service: MICROSERVICES.BEES_GROW_ORDER_TAKING_BFF,
      endpoint: `${ENDPOINTS.GET_MISSION_DETAILS}`,
      queryParams: { missionKeys: missionKey },
      pathParams: { accountId },
    }),
};
