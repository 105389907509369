import { BadgeCounter } from "@bees-grow-shared/components";
import { tokens } from "@bees-grow-shared/theme";
import { IconButton as HexaIconButton } from "@hexa-ui/components";
import styled from "styled-components";

export const IconButton = styled(HexaIconButton)`
  border: ${tokens.measure["border-width"].hairline} solid
    ${tokens.color.foundation.border.default};
  background: ${tokens.color.foundation.bg["primary-clear"]};
`;

export const Container = styled.div`
  position: relative;
  width: 3.125rem;
`;

export const PositionedBadge = styled(BadgeCounter)`
  position: absolute;
  top: 0px;
  left: 1.875rem;
`;
