import { useIntl } from "react-intl";

import { BadgeCounter } from "@bees-grow-shared/components";
import { Heading } from "@hexa-ui/components";

import * as Styled from "./CarouselTitle.styles";

interface CarouselTitleProps {
  tasksCount?: number;
}

export default function CarouselTitle({ tasksCount = 0 }: CarouselTitleProps) {
  const { formatMessage } = useIntl();

  return (
    <Styled.Container data-testid="carousel-title">
      <Heading size="H3" data-testid="carousel-title-heading">
        {formatMessage({
          id: "TasksCarousel.TITLE",
        })}
      </Heading>
      {tasksCount > 0 && (
        <BadgeCounter
          data-testid="carousel-title-badge-counter"
          size="medium"
          quantity={tasksCount}
        />
      )}
    </Styled.Container>
  );
}
